.tri-state-switch-container {
  position: relative;
  height: 25px;
  width: 70px;
  min-width: 70px;
  background: $dark-background-color;
  border-radius: 20px;

  > div {
    position: absolute;
    height: 25px;
    width: 25px;
    left: calc(50% - 15px);
    background-color: #ffffff;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    border: 1px solid $background-color;
    cursor: pointer;
  }

  &.yes-switch {
    background-color: $primary-color;

    > div {
      left: calc(100% - 25px);
      border-color: $primary-color;
    }

    &:after {
      content: 'Yes';
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 9px);
      font: $semiBoldTitle;
      color: white;
    }
  }

  &.no-switch {
    background-color: $error-color;

    &:after {
      content: 'No';
      position: absolute;
      left: 50%;
      top: calc(50% - 9px);
      font: $semiBoldTitle;
      color: white;
    }

    > div {
      left: 0;
      border-color: $error-color;
    }
  }
}


