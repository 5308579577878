$h1: 3.75em;
$h2: 2.5em;
$h3: 2.125em;
$h4: 1.5em;
$h5: 1.25em;
$h6: 1.125em;
$title: 16px;
$subtitle: 14px;
$field: 12px;

$font-family: "Calibri", sans-serif;

$regularField: 400 $field $font-family;
$semiBoldField: 500 $field $font-family;
$boldField: 600 $field $font-family;

$regularSubTitle: 400 $subtitle $font-family;
$semiBoldSubTitle: 500 $subtitle $font-family;
$boldSubTitle: 600 $subtitle $font-family;

$regularTitle: 400 $title $font-family;
$semiBoldTitle: 500 $title $font-family;
$boldTitle: 600 $title $font-family;

$regularH6: 400 $h6 $font-family;
$semiBoldH6: 500 $h6 $font-family;
$boldH6: 600 $h6 $font-family;

$regularH5: 400 $h5 $font-family;
$semiBoldH5: 500 $h5 $font-family;
$boldH5: 600 $h5 $font-family;

$regularH4: 400 $h4 $font-family;
$semiBoldH4: 500 $h4 $font-family;
$boldH4: 600 $h4 $font-family;

$regularH3: 400 $h3 $font-family;
$semiBoldH3: 500 $h3 $font-family;
$boldH3: 600 $h3 $font-family;

$regularH2: 400 $h2 $font-family;
$semiBoldH2: 500 $h2 $font-family;
$boldH2: 600 $h2 $font-family;

$regularH1: 400 $h1 $font-family;
$semiBoldH1: 500 $h1 $font-family;
$boldH1: 600 $h1 $font-family;


