.view-table-detail-modal {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;

  .modal-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px 0;
  }

  .modal-details-wrapper {
    padding: 20px 60px;

    .modal-details-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      align-items: center;
      padding: 5px 0;
      gap: 10px;

      .label-value-grid {
        display: grid;
        grid-template-columns: 110px 220px;
        grid-gap: 10px;
        font: $semiBoldSubTitle;

        > span {
          padding: 5px;
          color: $primary-color;
          font: $boldSubTitle;
        }

        .detail-value-field {
          padding: 5px;
          min-width: 170px;
          max-width: 220px;
          color: $field-color;
          overflow-wrap: break-word;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .view-table-detail-modal .modal-body {
    padding: 20px;
  }
  .view-table-detail-modal .modal-details-wrapper {
    padding: 0;

    .modal-details-grid {
      grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
      padding: 0;
    }
  }
}
