.table-class {
  width: 100%;
  border-spacing: 0;
  z-index: 1;

  th {
    max-width: 350px;
    position: sticky;
    height: 2.5rem;
    top: 0;
    padding: 0.313rem 1.25rem;
    background-color: $background-color;
    font: $boldSubTitle;
    color: $field-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
  }

  td {
    max-width: 350px;
    height: 2.5rem;
    padding: 0.4rem 1.25rem;
    font: $regularSubTitle;
    color: $field-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .link {
      color: $field-color;
      text-decoration: underline;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  tr:first-child {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  td.fixed-action-menu {
    overflow: visible;
  }

  .fixed-action-menu {
    position: sticky;
    right: 0;
    color: $placeholder-color;

    .material-icons-round:hover {
      color: $field-color;
    }
  }

  .fixed-action-menu-clicked {
    z-index: 1;
  }
}

.table-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100px;
}

.table-checkbox-header {
  width: 100px;
  max-width: 100px;
  text-align: center;
}

.table-action-buttons {
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 7px;
  }
}

.table-tag {
  padding: 5px 10px;
  color: $primary-color;
  background-color: #DFF0D5;
  border-radius: 5px;
}
