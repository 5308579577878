.summary-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.summary-detail-left-part {
  width: 59%;

  .form-detail-title {
    min-width: 180px;
  }
}

.summary-detail-right-part {
  width: 39%;

  .form-detail-title {
    min-width: 140px;
  }
}

.summary-detail-row {
  display: flex;
  margin-bottom: 10px;
}

.summary-detail {
  width: 100%;

  & > * {
    width: 100%;
  }

  .tri-state-switch-container {
    width: fit-content;
  }
}

.form-b-table-container {
  border-radius: 10px;
  overflow: auto;
}

.accredited-content-container table.form-b-table {
  width: 100%;
  min-width: 100%;
  border-spacing: 0;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow-x: auto;

  th {
    font: $semiBoldTitle;
    color: $field-color;
    background-color: $dark-background-color;
  }

  th, td, tr {
    border: 1px solid $background-color;
  }

  th, td {
    padding: 10px;
  }

  th:first-child {
    border-top-left-radius: 10px;
  }

  th:last-child {
    border-top-right-radius: 10px;
  }

  tr:nth-child(even) td {
    background-color: white;
  }

  tr:nth-child(odd) td {
    background-color: $background-color;
  }

  tr:last-child {
    border: 0;

    td {
      border-top: 0;
      border-bottom: 0;
    }
  }

  tr {
    td:first-child {
      border-left: 0;
    }

    td:last-child {
      border-right: 0;
    }
  }
}

.form-b-light-title {
  color: $placeholder-color;
  text-align: center;
}

.form-b-subtitle-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  > div {
    padding: 10px 10px 0 10px;
  }
}

.form-b-single-record-grid {
  display: grid;
  grid-template-columns: 140px 1fr;
  gap: 15px;
}

.accredited-content-container table.form-b-table.name-of-applicants {
  min-width: 100%;
  width: unset;
  overflow-x: auto;
}

.summary-detail-row {
  display: flex;

  .form-detail-title {
    margin-right: 10px;
  }
}

.summary-applicant-name {
  width: 40%;
  min-width: 200px
}

.summary-applicant-clinical, .summary-applicant-registrar, .summary-applicant-considered-suitable {
  width: 10%;
  min-width: 130px;
}

.summary-applicant-applied-for {
  width: 30%;
  min-width: 150px;
}

@media (max-width: 1024px) {
  .form-b-single-record-grid {
    grid-template-columns: 1fr;
    gap: 5px
  }

  .summary-detail-left-part, .summary-detail-right-part {
    width: 100%;
  }

  .summary-detail-left-part {
    .summary-detail-row {
      flex-wrap: wrap;

      .form-detail-title {
        width: 100%;
        margin: 0 0 5px 0;
      }
    }
  }

  .summary-detail-right-part {
    margin-top: 10px;
  }
}
