$primary-color: #71bf44;
$primary-variant-color: #4b7f2d;
$secondary-color: #d7df21;
$secondary-variant-color: #8f9416;
$active-color: #151d26;
$border-color: #e7ecf2;
$placeholder-color: #b8c1cc;
$field-color: #66737f;
$background-color: #f2f5f8;
$dark-background-color: #e2e9f0;
$success-color: #38c976;
$warning-color: #f49616;
$error-color: #ee581a;
$error-variant-color: #fe6767;
$alert-blue: #4495d1;
