@import 'color-palette';
@import 'typography';
@import 'common-class';
@import 'basic-styles';
@import "node_modules/react-datepicker/dist/react-datepicker";
@import 'modified-library';

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.f-14 {
  font-size: $subtitle;
}

.f-bold {
  font-weight: bold;
}

.f-16 {
  font-size: $title;
}

.f-h1 {
  font-size: $h1;
}

.f-h2 {
  font-size: $h2;
}

.font-primary {
  color: $primary-color;
}

.font-secondary {
  color: $secondary-color;
}

.font-placeholder {
  color: $placeholder-color;
}

.font-success {
  color: $success-color;
}

.font-field {
  color: $field-color;
}

.font-danger {
  color: $error-color;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.just-center {
  justify-content: center !important;
}

.just-bet {
  justify-content: space-between !important;
}

.just-end {
  justify-content: flex-end !important;
}

.just-start {
  justify-content: flex-start !important;
}

.align-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-15 {
  margin-left: 0.938rem;
}

.ml-20 {
  margin-left: 1.25rem;
}

.mr-5 {
  margin-right: 0.313rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-15 {
  margin-right: 0.938rem;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-15 {
  margin-top: 0.938rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 0.313rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-15 {
  margin-bottom: 0.938rem;
}

.mb-30 {
  margin-bottom: 30px;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-white {
  background-color: #fff;
}

.bg-background-color {
  background-color: $background-color;
}

.form-title {
  width: 80px;
  font: $regularSubTitle;
  color: $primary-color;
}

.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: 20px;
  font: $regularSubTitle;
  color: $primary-color;
}
