.standard-detail-table {
  border-spacing: 0.4rem 1.5rem;
}

.standard-detail-row {
  display: flex;
  margin-bottom: 10px;
}

.standard-detail {
  display: flex;
  align-items: flex-start;
  flex: 1;
  max-width: calc(100% - 235px);
}

.standard-texts {
  font: $semiBoldSubTitle;
  color: $field-color;
  margin-bottom: 14px;
  text-align: justify;
}

.standard-detail-container {
  flex: 1;
  margin-bottom: 10px;
}

.standard-detail-point {
  font: $regularTitle;
  color: $field-color;

  ul {
    list-style: none;
    padding-left: 20px;
    position: relative;

    li::before {
      content: "-";
      position: absolute;
      margin-left: -20px;
    }
  }
}

.standard-detail-button {
  width: 130px;
  vertical-align: top;
}

.standard-detail-remark {
  width: 200px;
}

.attach-button {
  width: 100%;
  padding: 5px 25px;
  font: $semiBoldTitle;
  border-radius: 5px;

  .material-icons {
    margin-right: 5px;
    font-size: 20px;
  }
}

.standard-error-message {
  margin: 5px 0;
}

.standard-attached-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
  padding: 5px;
  font: $semiBoldSubTitle;
  background-color: $border-color;
  border-radius: 5px;

  .material-icons-round {
    min-width: 24px;
    padding: 2px;
    font-size: 20px;
    background-color: $background-color;
    border-radius: 5px;
    cursor: pointer;
  }

  .download-file {
    margin-right: 5px;
    color: $alert-blue;

    &:hover {
      color: #448888;
    }
  }

  .delete-file {
    color: $error-color;

    &:hover {
      color: red;
    }
  }
}

.file-name {
  display: grid;
  grid-template-columns: calc(100% - 30px) 30px;
}

.file-name-without-extension {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.remarks-input {
  width: 100%;

  > textarea {
    width: inherit;
  }
}

@media (max-width: 1024px) {
  .standard-detail-row {
    flex-wrap: wrap;
  }

  .standard-detail {
    min-width: calc(100% - 85px);
  }

  //.standard-detail-button {
  //  display: none;
  //}

  .attach-button {
    width: unset;
    margin: 5px 0 0 85px;
  }
}
