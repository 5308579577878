.forbidden-access-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $primary-color;

  button {
    margin-top: 10px;
  }
}
