.policy-detail-content-container {
  display: grid;
  grid-template-columns: 140px 1fr 160px 1fr;
  gap: 10px 20px;
}

@media (max-width: 1280px) {
  .policy-detail-content-container {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}
