.final-checklist-grid {
  display: grid;
  gap: 10px
}

.final-checklist {
  display: flex;
  margin-bottom: 10px;
  color: $field-color;

  .tri-state-switch-container {
    margin-top: 5px;
  }

  & > div {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .final-checklist-grid {
    grid-template-columns: 1fr;
    gap: 5px
  }
}
