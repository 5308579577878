.tab-wrapper {
  position: relative;
  display: flex;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 10px;
}

.tab-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab-container::-webkit-scrollbar {
  display: none;
}

.tab {
  margin-right: 10px;
  padding: 14px;
  font: $semiBoldTitle;
  color: $field-color;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 10px;
}

.tab:last-of-type {
  margin-right: 0;
}

.active-tab {
  color: white;
  background-color: $primary-color;
}

.tab-content-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.tab-prev-next-button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 5px;
  font-size: 30px;
  max-width: 25px;
  color: #ffffff;
  background-color: $secondary-color;
  cursor: pointer;
  outline: none;
  z-index: 20;
}

.tab-prev-button {
  left: 0;
  border-right: 1px solid $field-color ;
}

.tab-next-button {
  right: 0;
  border-left: 1px solid $field-color;
}

.tab-prev-next-button:disabled {
  color: #6C7478;
 background-color: #2F3A3F;
}

.dummy-tab-div {
  height: 50px;
  width: 30px;
  padding: 0 10px;
}

@media (max-width: 600px) {
  .tab-wrapper {
    border-radius: 0;
  }
}
