.common-switch {
  display: none;
}

.common-switch + label {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
  background: $border-color;
  border-radius: 40px;
  cursor: pointer;
}

.common-switch + label div {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 50%;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.common-switch:checked + label {
  background: $primary-color;
}

.common-switch:checked + label div {
  left: 100%;
  border: 1px solid $primary-color;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.common-switch input {
  height: 0;
  width: 0;
}
