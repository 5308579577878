.upload-file-modal {
  width: 440px;
  height: 310px;
  display: flex;
  flex-direction: column;

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px 0;

    .upload-file-wrapper {
      width: 80%;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $field-color;
      background-color: $background-color;
      cursor: pointer;
      border-radius: 10px;

      .upload-file-text {
        margin-left: 10px;
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@media (max-width: 768px) {
  .upload-file-modal {
    width: 90%;
  }
}
