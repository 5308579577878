.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  padding: 20px;
}

.loader,
.loader::before,
.loader::after {
  width: 1em;
  height: 4em;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
}

.loader::before {
  background-color: $primary-color;
  color: $primary-color;
}

.loader::after {
  background-color: $secondary-color;
  color: $secondary-color;
}

.loader {
  position: relative;
  text-indent: -9999em;
  margin: 88px auto;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  font-size: 11px;
  color: $primary-variant-color;
  background-color: $primary-variant-color;
}

.loader::before,
.loader::after {
  position: absolute;
  top: 0;
  content: '';
}

.loader::before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader::after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
