.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 24px;
  padding-left: 35px;
  font: $regularTitle;
  color: $field-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: 1px solid #BBC0C5;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: $background-color;
}

input[type="checkbox" i]:disabled.checkbox-container:hover input ~ .checkmark {
  background-color: white !important;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $primary-color;
  border-color: $primary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark::after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
