.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: screen;
  z-index: 10;
}

.modal-content {
  min-width: 400px;
  background-color: white;
  border-radius: 10px;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  font: $boldH5;
  color: $primary-color;
  border-bottom: 1px solid $border-color;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.modal-body {
  padding: 20px 30px 0 30px;

  //tr:hover {background-color: $background-color}
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 30px;
}

.modal-footer-buttons {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .modal-content {
    min-width: 90%;
  }
}
