.supervisor-details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  gap: 10px 3%;
  margin: 10px 0;
}

@media (max-width: 1280px) {
  .supervisor-details-grid {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}
