.login-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  > * {
    height: 100%;
  }
}

.login-left-part {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10% 3%;
}

.login-title {
  margin-bottom: 10px;
  line-height: 1;

  img {
    height: auto;
    width: 100%;
    margin-bottom: 5vh;
  }

  > div:first-child {
    margin-top: 10px;
  }
}

.welcome-title {
  font: $boldH3;
  color: $secondary-color;
  font-size: 3vw;
}

.welcome-text{
  margin: 10px 0 25px 0;
  font-size: 2vw;
}

.EV-name {
  font: $boldH3;
  color: $primary-color;
  font-size: 3vw;
  margin: 10px 0;
}

.auth-strip {
  height: 100%;
  width: 24px;
  border: 1px solid white;
  z-index: 2;
}

.login-right-part {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login_images/login-right-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.login-left-part {
  width: calc(40% - 12px);
}

.login-right-part {
  width: calc(60% - 12px);
}

.input-container.auth-input-container {
  height: unset;
  margin-top: 5px;
  padding: 12px;
  border-radius: 10px;
}

.auth-input-icon {
  margin-right: 5px;
  color: $primary-color;
}

.auth-input-title {
  margin-top: 15px;
  font: $boldTitle;
  color: $field-color;
}

.remember-me-forgot-pswrd-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.forgot-password-link {
  font: $semiBoldSubTitle;
  color: $field-color;
}

.auth-evgp-bg-logo {
  position: absolute;
  right: -3vw;
  bottom: 3vh;
  height: 20%;
  filter: grayscale(1) brightness(.7) contrast(1.7);
}

.button.auth-submit-button {
  width: fit-content;
  margin-top: 20px;
  font: $semiBoldTitle;
}

@media (min-width: 1024px){
  .welcome-title {
    font-size: 2vw;
  }
  
  .welcome-text{
    margin: 10px 0 25px 0;
    font-size: 1.5vw;
  }

  .EV-name {
    font-size: 2vw;
    margin: 10px 0;
  }
 }

/* Progressive Enhancement for Jumbo sized Screen Handling... */
@media (min-width: 2166px){
  .login-title{
    img{
      max-width:662px;
    }
  }

  .login-left-part {
    width: calc(30% - 12px);
    min-width:calc(2166px * .4 - 12px);
  }
  
  .login-right-part {
    width: calc(70% - 12px);
    min-width: calc(2166px *.6 - 12px);
  }

  .EV-name{
      font-size: 44px;
  }

  .welcome-title{
      font-size: 44px;
  }

  .welcome-text{
      font-size: 32px;
  }
}

/* Progressive Enhancement for Smaller Device */
@media (max-width: 1024px) {
  .login-wrapper {
    flex-direction: column-reverse;

    > * {
      height: unset;
      width: 100%;
    }

    .login-left-part {
      height: calc(60% - 14px);
      overflow:scroll;
    }

    .login-right-part {
      height: 40%;
    }

    .auth-strip {
      height: 14px;
    }
  }

  .login-wrapper {
    .login-left-part {
      overflow:scroll;
    }
  }

  .login-title {
    img{
      height: 7vh;
      width: auto;
    }
  }
  .auth-evgp-bg-logo {
    right: 0;
    bottom: 0;
  }
}
